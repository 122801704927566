import React from "react";
import { IErrorContainerProps } from "./ErrorContainer.types";
import { StyledWrapper } from "./ErrorContainer.styles";

const ErrorContainer: React.FC<IErrorContainerProps> = () => {
  return (
    <StyledWrapper>
      <img src={"error.png"} width="700px" height="full" alt="card" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <span style={{ fontSize: "14px", lineHeight: "20px" }}>
          N'hésitez pas à prendre contact avec nous en envoyant un email à{" "}
          <a href="mailto:contact@medicheck.io">contact@medicheck.io</a> si le
          problème persiste!
        </span>
        <span style={{ fontSize: "14px", lineHeight: "20px" }}>
          Aarzel niet om contact met ons op te nemen door een e-mail te sturen
          naar <a href="mailto:contact@medicheck.io">contact@medicheck.io</a>{" "}
          als het probleem blijft bestaan!
        </span>
        <span style={{ fontSize: "14px", lineHeight: "20px" }}>
          Don't hesitate to contact us by sending an email to{" "}
          <a href="mailto:contact@medicheck.io">contact@medicheck.io</a> if the
          problem persists!
        </span>
      </div>
    </StyledWrapper>
  );
};

export default ErrorContainer;
