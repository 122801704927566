import React, { ReactElement } from "react";

import Lottie from "react-lottie";

interface ILoaderProps {}

const Loader: React.FC<ILoaderProps> = (): ReactElement<any> => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('./assets/loader.json'),
    
  };

  return <Lottie options={defaultOptions} height={500} width={500} />;
};

export default Loader;
