import React, { useEffect, useState } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { useNavigate } from '@reach/router';

import { IMainContainerProps } from './MainContainer.types';
import { base64ToBlob } from './MainContainer.helpers';
import { StyledWrapper } from './MainContainer.styles';
import { Loader } from '../../components/Loader';
import * as pdfMake from 'pdfmake/build/pdfmake';
import heic2any from 'heic2any';
interface IQueryString {
  secret?: string;
  downloadReportLink?: string;
}

const MainContainer: React.FC<IMainContainerProps> = ({ location }) => {
  const navigate = useNavigate();
  const parsed: IQueryString = queryString.parse(location.search);
  const [isLoading, setIsloading] = useState(false);
  const [decodedSecret, setDecodedSecret] = useState('');
  const [currentBlobUrl, setCurrentBlobUrl] = useState('');

  useEffect(() => {
    if (parsed.downloadReportLink) {
      // get download link from url query string
      const downloadReportLink = location.search.split(
        'downloadReportLink='
      )[1];
      console.log('link', downloadReportLink);

      // create link and click it
      const link = document.createElement('a');
      link.href = downloadReportLink;
      link.click();

      // close tab
      setTimeout(() => {
        window.close();
      }, 2000);
    }

    if (!parsed.secret && !parsed.downloadReportLink) {
      navigate('/error');
    }

    if (parsed.secret) {
      try {
        // Decode base64-encoded 'secret' parameter from URL, replacing spaces with '+'
        let fileLink = atob(
          parsed.secret.replace(/ /g, '+').replace(/\s/g, '')
        );
        // We have to do this because the CDN being used on Azure doesn't accept CORS of our previewer
        // Unable to change this for legacy support.
        if (fileLink.includes('https://d.medicheck.io/')) {
          fileLink = fileLink.replace(
            'https://d.medicheck.io/',
            'https://medicheckcertificates.blob.core.windows.net/res/'
          );
        }
        setDecodedSecret(fileLink);
      } catch (err) {
        navigate('/error');
      }
    }
  }, [parsed.secret, navigate, parsed.downloadReportLink, location.search]);

  useEffect(() => {
    async function call(url: string) {
      try {
        setIsloading(true);
        const isGenerated = url.includes('medicheck-backend');
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/octet-stream',
            Accept: 'application/octet-stream',
          },
          responseType: !isGenerated ? 'arraybuffer' : undefined,
        });
        if (response.data === '') {
          navigate('/error');
          return;
        }

        const blob = base64ToBlob(
          Buffer.from(response.data, 'binary').toString('base64')
        );

        let localDataUrl = URL.createObjectURL(blob);

        const urlWithoutParams = url.split('?')[0];

        if (
          urlWithoutParams?.toLowerCase().endsWith('.jpg') ||
          urlWithoutParams?.toLowerCase().endsWith('.png') ||
          urlWithoutParams?.toLowerCase().endsWith('.jpeg') ||
          urlWithoutParams?.toLowerCase().endsWith('.heic')
        ) {
          const img = new Image();
          // need this for the canvas toDataURL to work
          img.crossOrigin = 'anonymous';
          img.src = url;

          if (urlWithoutParams?.toLowerCase().endsWith('.heic')) {
            const arrayBuffer = response.data;
            const jpegBuffer = await heic2any({
              blob: new Blob([arrayBuffer], { type: 'image/heic' }),
              toType: 'image/jpeg',
            });

            const blob =
              jpegBuffer instanceof Blob ? jpegBuffer : jpegBuffer[0];
            img.src = URL.createObjectURL(blob);
          }

          img.onload = () => {
            const XratioW = img.width / 595;

            //3508 max height of a pdf page
            const XratioH = img.height / 804;
            let canvas = document.createElement('canvas');
            let context: any = canvas.getContext('2d');

            canvas.width = img.width / XratioW;
            canvas.height = img.height / XratioH;

            context.drawImage(
              img,
              0,
              0,
              img.width / XratioW,
              img.height / XratioH
            );

            var docDefinition: any = {
              content: [
                {
                  image: canvas.toDataURL('image/jpeg', 1.0),
                  width: img.width / XratioW,
                  height: img.height / XratioH,
                  alignment: 'center',
                },
              ],
            };

            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            pdfDocGenerator.getBlob((blob) => {
              setCurrentBlobUrl(URL.createObjectURL(blob));
            });
          };
        } else if (urlWithoutParams?.toLowerCase().endsWith('.pdf')) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);
          setCurrentBlobUrl(blobUrl);
        } else {
          if (!isGenerated) {
            localDataUrl = Buffer.from(response.data, 'binary').toString(
              'base64'
            );
          } else {
            localDataUrl = response.data;
          }
          setCurrentBlobUrl('data:application/pdf;base64,' + localDataUrl);
        }
        setIsloading(false);
      } catch (err) {
        navigate('/error');
      }
    }
    if (decodedSecret) {
      call(decodedSecret);
    }
  }, [decodedSecret, navigate]);

  return (
    <StyledWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        {isLoading && <Loader />}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '100%',
          }}
        >
          {!!currentBlobUrl && (
            <object
              data={currentBlobUrl}
              type={'application/pdf'}
              width='100%'
              height='100%'
            />
          )}
        </div>
      </div>
    </StyledWrapper>
  );
};

export default MainContainer;
